import { v4 as uuid } from 'uuid';
import { observable, action } from 'mobx';
import { BehaviorSubject } from 'rxjs';
import EVENT_LIST_DEPRECATED from './constants/deprecatedEvents';
import BLOATED_EVENTS from './constants/bloatedEvents';
interface Event {
  callback: Function,
  id: string,
  infinite: boolean
}

export class Events {
  private logger: any;
  private verboseLogging: boolean;
  private callbacks: Map<string, Array<Event>>;
  private eventIds: Map<string, string>;
  private streams: any;

  constructor() {
    this.logger = undefined;
    this.verboseLogging = false;
    this.callbacks = new Map();
    this.eventIds = new Map();
    this.streams = observable({});
  }

  @action public stream(event: string): any {
    if (!this.streams[event]) {
      this.streams[event] = new BehaviorSubject(null);
    }

    return this.streams[event];
  }

  public on(event: string | Array<string>, callback: Function, infinite: boolean = true): any {
    if (Array.isArray(event)) {
      return event.map((eventName: string) => this.on(eventName, callback)).join('|');
    }

    if (this.logger && this.eventDeprecated(event)) {
      this.logger.warning(
        'You are Listening for an Deprecated Event. Please adapt to our new event-naming conventions until the next Release',
        { event },
      );
    }

    const createdEvent: Event = {
      callback,
      infinite,
      id: uuid()
    };

    if (this.callbacks.has(event)) {
      this.callbacks.set(event, [
        ...this.callbacks.get(event),
        createdEvent
      ]);
    } else {
      this.callbacks.set(event, [createdEvent]);
    }

    this.eventIds.set(createdEvent.id, event);

    return createdEvent.id;
  }

  public once(event: string | Array<string>, callback: Function): any {
    return this.on(event, callback, false);
  }

  public off(eventUUId: string) {
    if (typeof eventUUId === 'string' && eventUUId.includes('|')) {
      return eventUUId.split('|').map((removeEvent: string) => this.off(removeEvent));
    }

    if (this.eventIds.has(eventUUId)) {
      const eventNamespace = this.eventIds.get(eventUUId);
      return this.unregister(eventNamespace, [eventUUId]);
    }

    return false;
  }

  public unregister(event: string, onlyCallbackId: Array<string> | undefined = undefined) {
    if (this.callbacks.has(event)) {
      if (onlyCallbackId) {
        const updateEvents: Array<Event> = this.callbacks.get(event).filter((checkEvent: Event) => {
          return !onlyCallbackId.find((id: string) => checkEvent.id === id);
        });
        onlyCallbackId.forEach((id: string) => this.eventIds.delete(id));

        if (updateEvents.length) {
          return !!this.callbacks.set(event, updateEvents);
        }
      }
      return this.callbacks.delete(event);
    }
    return false;
  }

  @action public trigger(event: string | Array<string>, payload: any = null): void {
    if (Array.isArray(event)) {
      return event.forEach((eventName: string) => this.trigger(eventName, payload));
    }

    if (this.streams[event]) {
      this.streams[event].next(payload);
    }

    // Note that we are deliberately not logging the payload or a hint to it.
    // Payloads tend to be nested objects containing circular references, we can not log them in any useful way
    // .. without bloating the log into oblivion, that is.
    if (this.logger && (!BLOATED_EVENTS.includes(event) || this.verboseLogging)) {
      this.logger.traceCall('trigger', event);
    }

    if (this.callbacks.has(event)) {
      const deleteEventList: Array<string> = [];

      this.callbacks.get(event).forEach((nextEvent: Event) => {
        nextEvent.callback(payload, nextEvent.id);
        if (nextEvent.infinite === false) {
          deleteEventList.push(nextEvent.id);
        }
      });

      if (deleteEventList.length) {
        this.unregister(event, deleteEventList);
      }
    }
  }

  public setLogger(logger: any, verboseLogging: boolean = false): void {
    this.logger = logger;
    this.verboseLogging = verboseLogging;
  }

  public eventDeprecated(eventName: string): boolean {
    return EVENT_LIST_DEPRECATED.includes(eventName);
  }
}

export default new Events();
export {
  EVENT_LIST_DEPRECATED,
  BLOATED_EVENTS
};