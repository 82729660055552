export default [
  'sdk.me.tick',
  'sdk.auth.me.time.tick',
  'onlineMonitor:groupMonitorData',
  'sdk.group-monitor.data',
  'sdk.agent-monitor.data',
  'onlineMonitor:agentMonitorData',
  'error:could not load config',
  'error: no response',
  'clear: got response',
  'sdk.auth.authenticating',
  'login:error',
  'auth:logout',
  'login:error',
  'call:callLogTargets:updated',
  'call:relevantObjects:updated',
  'call:relevantObjects:requested',
  'call:relevantObjects:selected',
  'api.voice.history.updated',
  'multichannel.api.groups.configReceived',
  'user: blocked groups received',
  'groups: groups filtered',
  'onlineMonitor:agentMonitorConfiguration',
  'initialized',
  'user:incomingCall',
  'call:recordingStates',
  'socket:error',
  'api.http-request.execute',
  'httpRequests: new request',
  'httpRequests: requests cleared',
  'user:status',
  'user:status:new',
  'user:status:call:begin',
  'user:status:call:end',
  'user:umsStatus',
  'user.receivedConfig',
  'user: currentCall dropped',
  'user: currentWorkItem dropped',
  'voip:connecting',
  'voip:connected',
  'voip:disconnected',
  'voip:registered',
  'voip:unregistered',
  'voip:registrationFailed',
  'voip:invite',
  'voip:error',
  'user:additionalWorkItemInfo',
  'user:newWorkItem',
  'sdk.work-item',
  'sdk.work-item.new',
  'pcweb.gui.composition.added',
  'sdk.calls.pickable.updated',
  'sdk.workitems.pickable.updated',
];