export default [
  'error:could not load config',
  'sdk.auth.authenticating',
  'login:error',
  'clear: got response',
  'error: no response',
  'api.call.mute-state-changed',
  'call.recording',
  'call:xpath',
  'call:end',
  'call:finished',
  'call:callLogTargets:updated',
  'call:relevantObjects:updated',
  'call:relevantObjects:requested',
  'call:relevantObjects:selected',
  'api.voice.history.updated',
  'multichannel.api.groups.configReceived',
  'groups: groups filtered',
  'user: blocked groups received',
  'onlineMonitor:agentMonitorConfiguration',
  'onlineMonitor:agentMonitorData',
  'user:customer config received',
  'onlineMonitor:groupMonitorData',
  'user:incomingCall',
  'call:recordingStates',
  'api.http-request.execute',
  'httpRequests: new request',
  'httpRequests: requests cleared',
  'user:status',
  'user:status:new',
  'user:status:call:begin',
  'user:status:call:end',
  'sdk.auth.me.time.tick',
  'user:umsStatus',
  'user: currentCall dropped',
  'user: currentWorkItem dropped',
  'user: received text info to be processed',
  'user.receivedConfig',
  'voip:registered',
  'voip:unregistered',
  'voip:registrationFailed',
  'voip:connecting',
  'voip:connected',
  'voip:disconnected',
  'voip:error',
  'voip:invite',
  'sdk.work-item.new',
  'user:additionalWorkItemInfo',
  'sdk.work-item',
  'user:newWorkItem',
  'initialized',
  'sdk.work-item.send',
  'sdk.work-item.canceled',
  'sdk.work-item.extended',
  'sdk.work-item.started',
  'sdk.work-item.stopped',
  'sdk.work-item.error',
  'sdk.work-item.defered',
];